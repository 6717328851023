import state from './moduleReceivableState.js'
import mutations from './moduleReceivableMutations.js'
import actions from './moduleReceivableActions.js'
import getters from './moduleReceivableGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

