//import Vue from 'vue'

export default {
  // SET (state, payload) {
  //   state.receipt = Object.assign({}, state.receipt, payload) //precisa ser assim para manter a reatividade
  // },
  SET_RECEIVABLES_GRID (state, payload) {
    state.receivablesGrid = payload
  }//,
  // STORE (state, payload) {
  //   state.receipts.push(payload) //precisa ser assim para manter a reatividade
  //   state.receipts.sort((a, b) => { return a.trading_name.localeCompare(b.trading_name) }) // Ordena por nome

  //   state.receiptsGrid.data.push(payload)
  //   state.receiptsGrid.data.sort((a, b) => { return a.trading_name.localeCompare(b.trading_name) }) // Ordena por nome
  //   state.receiptsGrid.total = state.receiptsGrid.total + 1
  // },
  // UPDATE (state, payload) {
  //   const idx = state.receipts.findIndex(o => o.id === payload.id)
  //   if (idx >= 0) Vue.set(state.receipts, idx, payload)

  //   const idx1 = state.receiptsGrid.data.findIndex(o => o.id === payload.id)
  //   if (idx1 >= 0) Vue.set(state.receiptsGrid.data, idx1, payload)
  // },
  // DELETE (state, payload) {
  //   const idx1 = state.receipts.findIndex(o => o.id === payload)
  //   if (idx1 >= 0) state.receipts.splice(idx1, 1) //precisa ser assim para manter a reatividade

  //   const idx = state.receiptsGrid.data.findIndex(o => o.id === payload)
  //   if (idx >= 0) {
  //     state.receiptsGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
  //     state.receiptsGrid.total = state.receiptsGrid.total - 1
  //   }
  // }
}
