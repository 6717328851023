import axios from '@/axios.js'

export default {
  // store ({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.post(`${process.env.VUE_APP_API_URL}/receipt`, payload)
  //       .then((response) => {
  //         commit('STORE', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  // update ({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.put(`${process.env.VUE_APP_API_URL}/receipt/${payload.id}`, payload)
  //       .then((response) => {
  //         commit('SET', response.data)
  //         commit('UPDATE', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/receivables`, payload)
        .then((response) => {
          commit('SET_RECEIVABLES_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }//,
  // delete ({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.delete(`${process.env.VUE_APP_API_URL}/receipt/${payload}`)
  //       .then((response) => {
  //         commit('DELETE', payload)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // }
}
