<template>
  <div v-if="params.data" class="flex items-center">
    {{ date }}
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'CellRendererDateTime',
  computed: {
    date () {
      const [YYYY, MM, DD] = (this.params.data.payday.slice(0, 10)).split('-')
      const payday = `${YYYY}-${MM}-${DD}`

      // C. Crédito
      if (this.params.data.type_payment.type_payment === 'CARTÃO DE CRÉDITO') {
        if (this.params.data.account.card_account.type_day_receipt_credit === 'DINAMIC') { // DINAMIC (RECEBE X DIAs após o pagamento) default('FIXED')
          return moment(payday, 'YYYY-MM-DD').add(this.params.data.account.card_account.day_receipt_credit, 'days').format('DD/MM/YYYY')
        } else if (this.params.data.account.card_account.type_day_receipt_credit === 'FIXED') { // FIXED (recebe sempre no mesmo dia do mês)
          // todo o dia x day_receipt_credit
          if (DD <= this.params.data.account.card_account.day_receipt_credit) {
            return `${this.params.data.account.card_account.day_receipt_credit}/${MM}/${YYYY}`
          } else {
            return moment(`${YYYY}-${MM}-${this.params.data.account.card_account.day_receipt_credit}`, 'YYYY-MM-DD').add(1, 'month').format('DD/MM/YYYY')
          }
        }
      // C. Débito
      } else if (this.params.data.type_payment.type_payment === 'CARTÃO DE DÉBITO') {
        if (this.params.data.account.card_account.type_day_receipt_debit === 'DINAMIC') { // DINAMIC (RECEBE X DIAs após o pagamento) default('FIXED')
          return moment(payday, 'YYYY-MM-DD').add(this.params.data.account.card_account.day_receipt_debit, 'days').format('DD/MM/YYYY')
        } else if (this.params.data.account.card_account.type_day_receipt_debit === 'FIXED') { // FIXED (recebe sempre no mesmo dia do mês)
          // todo o dia x type_day_receipt_debit
          if (DD <= this.params.data.account.card_account.type_day_receipt_debit) {
            return `${this.params.data.account.card_account.type_day_receipt_debit}/${MM}/${YYYY}`
          } else {
            return moment(`${YYYY}-${MM}-${this.params.data.account.card_account.type_day_receipt_debit}`, 'YYYY-MM-DD').add(1, 'month').format('DD/MM/YYYY')
          }
        }
      }
    }
  }
}
</script>
